import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import styled, { css } from 'styled-components'

import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import ChoiceQuestion from './ChoiceQuestion'
import TextQuestion from './TextQuestion'

const AcceptButton = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
  `}
`

const TermsAndConditionsWrapper = styled.article`
  padding: ${({ theme }) => theme.spacers.normal} 0;
`

export const Context = createContext()

function TermsAndConditions({
  course,
  slug,
  termsAndConditions,
  openNextQuestion,
}) {
  const { user } = useGlobalState()

  const acceptTerms = useCallback(async () => {
    const firestore = await getFirebaseFirestore()

    await firestore
      .doc(`users/${user.uid}/courses/${course.slug}/lessons/${slug}`)
      .set(
        {
          isRegistered: true,
        },
        { merge: true }
      )

    openNextQuestion()
  }, [openNextQuestion])

  return (
    <TermsAndConditionsWrapper>
      <MDXRenderer>{termsAndConditions}</MDXRenderer>
      <AcceptButton onClick={acceptTerms}>ยอมรับเงื่อนไข</AcceptButton>
    </TermsAndConditionsWrapper>
  )
}

function AllDone() {
  return <div>เสร็จสิ้นการสอบ โปรดรอผลการประเมิน...</div>
}

function ExamLesson({ isLoading, slug, course, secureLesson }) {
  const { user } = useGlobalState()
  const [isRegistered, setIsRegistered] = useState(false)
  const [isRegisteredLoaded, setIsRegisteredLoaded] = useState(false)
  const [isDateValid, setIsDateValid] = useState(false)
  const [isDateValidLoaded, setIsDateValidLoaded] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(-1)

  const openNextQuestion = useCallback(() => {
    if (currentQuestion === secureLesson?.questions.length - 1) {
      return setCurrentQuestion(-2)
    }

    setCurrentQuestion(currentQuestion + 1)
  }, [currentQuestion, setCurrentQuestion])

  const contextValue = useMemo(
    () => ({
      questionCount: secureLesson?.questions.length,
      currentQuestion,
      openNextQuestion,
    }),
    [secureLesson, currentQuestion, openNextQuestion]
  )

  useEffect(() => {
    const checkRegistered = async () => {
      if (!user) return

      const firestore = await getFirebaseFirestore()
      const snapshot = await firestore
        .doc(`users/${user.uid}/courses/${course.slug}/lessons/${slug}`)
        .get()

      setIsRegisteredLoaded(true)
      setIsRegistered(snapshot.data()?.isRegistered)
    }

    const checkDateValid = async () => {
      if (!user) return

      const firestore = await getFirebaseFirestore()
      const snapshot = await firestore
        .doc(`courses/${course.slug}/lessons/${slug}`)
        .get()

      setIsDateValidLoaded(true)
      setIsDateValid(new Date() <= new Date(snapshot.data()?.validBefore))
    }

    checkRegistered()
    checkDateValid()
  }, [user, course.slug, slug])

  if (isLoading || !isRegisteredLoaded || !isDateValidLoaded) {
    return <LoadingImage />
  }
  if (isRegistered) return <div>คุณไม่สามารถทำแบบทดสอบซ้ำได้</div>
  if (!isDateValid) return <div>หมดเวลาในการทำแบบทดสอบ</div>
  if (!secureLesson) return null
  if (currentQuestion === -1) {
    return (
      <TermsAndConditions
        course={course}
        slug={slug}
        openNextQuestion={openNextQuestion}
        termsAndConditions={secureLesson.termsAndConditions}
      />
    )
  }
  if (currentQuestion === -2) return <AllDone></AllDone>

  const question = secureLesson.questions[currentQuestion]
  const Component = {
    choice: ChoiceQuestion,
    text: TextQuestion,
  }[question.type]

  return (
    <Context.Provider value={contextValue}>
      <Component
        index={currentQuestion}
        slug={slug}
        course={course}
        {...question}
      />
    </Context.Provider>
  )
}

export default ExamLesson
