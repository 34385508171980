import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { Context } from './Tabs'

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacers.normal} 0;
  `}
`

export default function TabPane({ index, label, children }) {
  const { currentIndex } = useContext(Context)

  return currentIndex === index ? <Wrapper>{children}</Wrapper> : null
}
