import React, { createContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO';
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta';
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import useCourseSchema from '@babelcoder/gatsby-theme-courses/src/hooks/useCourseSchema';
import LessonDetails from './LessonDetails';

export const Context = createContext();

const Note = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.accent.primaryDarken15};
    padding: ${theme.spacers.small};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.normal};
    letter-spacing: 1px;
    text-align: center;
  `}
`;

function Lesson({ course, lesson }) {
  const allLessons = useMemo(
    () =>
      course.units.reduce((lessons, unit) => [...lessons, ...unit.lessons], []),
    [course, course.units]
  );
  const index = useMemo(
    () => allLessons.findIndex((l) => l.slug === lesson.slug),
    [allLessons, lesson]
  );
  const context = useMemo(
    () => ({
      currentIndex: index,
      isFirstLesson: index > 0,
      isLastLesson: index === allLessons.length - 1,
      prevLesson: allLessons[index - 1],
      nextLesson: allLessons[index + 1],
    }),
    [index, allLessons]
  );
  const { getCoursePath } = useCoursesPath();
  const getCourseSchema = useCourseSchema();
  const { siteUrl } = useSiteMeta();
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = course.coverImage.childImageSharp.fixed;
  const path = `${getCoursePath(course.slug)}/lessons/${lesson.slug}`;
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Thing',
    name: lesson.title,
    description: lesson.desc,
    url: toSingleSlash(`${siteUrl}/${path}`),
    subjectOf: getCourseSchema(course),
  };

  return (
    <Context.Provider value={context}>
      <SEO
        title={lesson.title}
        type="article"
        urlPath={path}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        desc={lesson.desc}
        schema={schema}
        ignoreAuthorAndPublisher={true}
      ></SEO>
      {course.courseInsideNote && <Note>{course.courseInsideNote}</Note>}
      <LessonDetails course={course} lesson={lesson}></LessonDetails>
    </Context.Provider>
  );
}

export default Lesson;
