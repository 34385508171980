import { useState, useEffect, useRef } from 'react'

const useQuestionTimer = (time, timeoutHandler, deps = []) => {
  const [remainingTime, setRemainingTime] = useState(time)
  const timerRef = useRef(null)

  useEffect(() => {
    if (remainingTime <= 0) {
      console.log('call handler')
      timeoutHandler()
      clearInterval(timerRef.current)
      return
    }

    timerRef.current = setInterval(() => {
      setRemainingTime(remainingTime - 1)
    }, 1000)

    return () => {
      clearInterval(timerRef.current)
    }
  }, [remainingTime, setRemainingTime])

  useEffect(() => {
    setRemainingTime(time)
  }, deps)

  return remainingTime
}

export default useQuestionTimer
