import React from 'react';
import { graphql } from 'gatsby';

import Lesson from '../components/courses/Lesson';

export default function LessonTemplate({
  data: { course },
  pageContext: { lesson },
}) {
  return <Lesson course={course} lesson={lesson}></Lesson>;
}

LessonTemplate.layout = {
  headerType: 'fixed',
  toggleable: true,
  hasFooter: false,
};

export const pageQuery = graphql`
  query LessonQuery($courseSlug: String!) {
    course: coursesYaml(slug: { eq: $courseSlug }) {
      slug
      name
      excerpt
      courseInsideNote
      units {
        title
        lessons {
          title
          type
          slug
          hasCode
          forLive
        }
      }
      coverImage: image {
        childImageSharp {
          fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`;
