import React, { useContext, useCallback, lazy, Suspense } from 'react'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { FaPlay } from 'react-icons/fa'
import { Link } from 'gatsby'
import { down } from 'styled-breakpoints'

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import { Context as LessonContext } from './Lesson'

const VideoPlayer = lazy(() => import('./VideoPlayer'))

const Placeholder = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-top: 56.25%;
    background-color: ${theme.colors.neutral.black};
    border-radius: ${theme.round.large};
  `}
`

const PlaceholderContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transform: translate(-50%, -50%);
    color: ${theme.colors.neutral.gray800};
  `}
`

const PlaceholderDesc = styled.p`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacers.normal};
`

const RegisterLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.neutral.gray1000};
    border: 5px solid ${theme.colors.neutral.white};
    border-radius: ${theme.round.large};
    outline: none;
    transition: background-color 0.25s;

    &:hover {
      background-color: ${theme.colors.main.primary};
    }

    ${down('medium')} {
      font-size: ${theme.fontSizes.normal};
    }
  `}
`

const RegisterText = styled.span`
  line-height: 1;
  margin-left: ${({ theme }) => theme.spacers.small};
`

function VideoLesson({ course, isLoading, secureLesson }) {
  const isSSR = typeof window === 'undefined'
  const { getCoursePath, getLessonPath } = useCoursesPath()
  const { isLastLesson, nextLesson } = useContext(LessonContext)

  const navigateToNextLesson = useCallback(() => {
    if (isLastLesson) return

    navigate(getLessonPath(course.slug, nextLesson.slug))
  }, [isLastLesson, nextLesson])

  return (
    <div>
      {!isSSR && secureLesson?.video ? (
        // <VideoPlayer
        //   custom
        //   url={secureLesson.video}
        //   title={title}
        //   desc={desc}
        // ></VideoPlayer>
        <React.Suspense fallback={<div />}>
          <VideoPlayer
            controls
            url={secureLesson.video}
            onEnded={navigateToNextLesson}
          ></VideoPlayer>
        </React.Suspense>
      ) : (
        <Placeholder>
          <PlaceholderContainer>
            {isLoading ? (
              <LoadingImage></LoadingImage>
            ) : (
              <>
                <PlaceholderDesc>
                  กรุณาลงทะเบียนเรียนก่อนเข้าชมคอร์สดังกล่าว
                </PlaceholderDesc>
                <RegisterLink to={getCoursePath(course.slug)}>
                  <FaPlay></FaPlay>
                  <RegisterText>สมัครเรียน</RegisterText>
                </RegisterLink>
              </>
            )}
          </PlaceholderContainer>
        </Placeholder>
      )}
    </div>
  )
}

export default VideoLesson
