import React, { createContext, useState, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'

export const Context = createContext()

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacers.normal} 0;
  `}
`

const Labels = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacers.normal} 0;
    border-bottom: 1px solid ${theme.colors.neutral.gray300};
  `}
`

const Label = styled.div`
  ${({ theme, active }) => css`
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    border-radius: ${theme.round.medium};
    cursor: pointer;

    ${active
      ? css`
          color: ${theme.colors.neutral.white};
          background-color: ${theme.colors.main.primary};
        `
      : css`
          &:hover {
            color: ${theme.colors.main.secondary};
          }
        `}
  `}
`

export default function Tabs({ defaultIndex, children }) {
  const [currentIndex, setCurrentIndex] = useState(
    defaultIndex || children?.[0]?.props?.index
  )
  const [labels, setLabels] = useState(
    children.filter(Boolean).map((child) => child.props.label)
  )

  const addLabel = useCallback((label) => setLabels([...labels, label]), [
    labels,
    setLabels,
  ])

  const contextValue = useMemo(
    () => ({
      currentIndex,
      setCurrentIndex,
      labels,
      setLabels,
      addLabel,
    }),
    [currentIndex, setCurrentIndex, labels, setLabels, addLabel]
  )

  const changeTab = useCallback((index) => () => setCurrentIndex(index), [
    setCurrentIndex,
  ])

  return (
    <Context.Provider value={contextValue}>
      <Wrapper>
        <Labels>
          {labels.map((label, index) => (
            <Label
              key={index}
              active={index === currentIndex}
              onClick={changeTab(index)}
            >
              {label}
            </Label>
          ))}
        </Labels>
        {children}
      </Wrapper>
    </Context.Provider>
  )
}
