import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'

import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import LessonTitle from './LessonTitle'
import { Context as LessonContext } from './Lesson'

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
`

const StyledLessonTitle = styled(LessonTitle)`
  margin-left: auto;
  margin-right: auto;
`

const iconStyles = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xlarge};
    color: ${theme.colors.main.secondary};
  `}
`

export default function LessonHeader({ lesson, course }) {
  const { getLessonPath } = useCoursesPath()
  const { isFirstLesson, isLastLesson, prevLesson, nextLesson } = useContext(
    LessonContext
  )

  return (
    <Wrapper>
      {isFirstLesson && (
        <Link to={getLessonPath(course.slug, prevLesson.slug)}>
          <FaArrowCircleLeft css={iconStyles}></FaArrowCircleLeft>
        </Link>
      )}
      <StyledLessonTitle>{lesson.title}</StyledLessonTitle>
      {!isLastLesson && (
        <Link to={getLessonPath(course.slug, nextLesson.slug)}>
          <FaArrowCircleRight css={iconStyles}></FaArrowCircleRight>
        </Link>
      )}
    </Wrapper>
  )
}
