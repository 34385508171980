import React, { useState, useCallback, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import Checkbox from '@babelcoder/gatsby-theme-base/src/components/Checkbox'
import { Context } from './ExamLesson'
import useQuestionTimer from '../../hooks/useQuestionTimer'

const Question = styled.header`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1000};
    font-size: ${theme.fontSizes.normal};
  `}
`

const SubmitAnswer = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
  `}
`

const Timer = styled.div`
  ${({ theme }) => css`
    position: sticky;
    bottom: ${theme.spacers.normal};
    right: ${theme.spacers.normal};
    padding: ${theme.spacers.xsmall} ${theme.spacers.normal};
    background-color: ${theme.colors.main.info};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.large};
    border-radius: ${theme.round.normal};
    width: fit-content;
    margin-left: auto;
  `}
`

const ChoiceQuestion = ({ slug, index, question, choices, time, course }) => {
  const { user } = useGlobalState()
  const [answers, setAnswers] = useState([])
  const { openNextQuestion } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)

  const remainingTime = useQuestionTimer(
    time,
    () => {
      submitAnswers()
    },
    [index]
  )

  const setAnswer = useCallback(
    (answer) => () => {
      const newAnswers = answers.includes(answer)
        ? answers.filter((ans) => ans !== answer)
        : [...answers, answer]

      setAnswers(newAnswers)
    },
    [answers, setAnswers]
  )

  const submitAnswers = async () => {
    const firestore = await getFirebaseFirestore()

    setIsLoading(true)
    await firestore
      .collection(
        `users/${user.uid}/courses/${course.slug}/lessons/${slug}/questions`
      )
      .doc(`question-${index + 1}`)
      .set(
        {
          type: 'choice',
          answers,
        },
        { merge: true }
      )

    setIsLoading(false)
    setAnswers([])
    openNextQuestion()
  }

  if (isLoading) return <LoadingImage />

  return (
    <article>
      <Question>
        <MDXRenderer>{question}</MDXRenderer>
      </Question>
      {choices.map((choice) => (
        <Checkbox
          key={`${index}-${choice.key}`}
          onChange={setAnswer(choice.key)}
          htmlFor={`${index}-${choice.key}`}
        >
          <MDXRenderer>{choice.title}</MDXRenderer>
        </Checkbox>
      ))}
      <SubmitAnswer onClick={submitAnswers}>ยืนยันคำตอบ</SubmitAnswer>
      <Timer>{remainingTime} วินาที</Timer>
    </article>
  )
}

export default ChoiceQuestion
