import React, { useState, useCallback, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import { Context } from './ExamLesson'
import useQuestionTimer from '../../hooks/useQuestionTimer'

const Question = styled.header`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1000};
    font-size: ${theme.fontSizes.normal};
  `}
`

const SubmitAnswer = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
  `}
`

const Timer = styled.div`
  ${({ theme }) => css`
    position: sticky;
    bottom: ${theme.spacers.normal};
    right: ${theme.spacers.normal};
    padding: ${theme.spacers.xsmall} ${theme.spacers.normal};
    background-color: ${theme.colors.main.info};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.large};
    border-radius: ${theme.round.normal};
    width: fit-content;
    margin-left: auto;
  `}
`

const Answer = styled.textarea`
  ${({ theme }) => css`
    width: 100%;
    height: 500px;
    border: none;
    outline: none;
    font-size: ${theme.fontSizes.normal};
    margin-top: ${theme.spacers.normal};
    resize: none;

    &::placeholder {
      color: ${theme.colors.neutral.gray900};
    }
  `}
`

const TextQuestion = ({ slug, index, question, time, course }) => {
  const { user } = useGlobalState()
  const [answer, setAnswer] = useState('')
  const { openNextQuestion } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)

  const remainingTime = useQuestionTimer(
    time,
    () => {
      submitAnswer()
    },
    [index]
  )

  const changeAnswer = useCallback(
    (event) => {
      setAnswer(event.target.value)
    },
    [setAnswer]
  )

  const submitAnswer = async () => {
    const firestore = await getFirebaseFirestore()

    setIsLoading(true)
    await firestore
      .collection(
        `users/${user.uid}/courses/${course.slug}/lessons/${slug}/questions`
      )
      .doc(`question-${index + 1}`)
      .set(
        {
          type: 'text',
          answer,
        },
        { merge: true }
      )

    setAnswer('')
    setIsLoading(false)
    openNextQuestion()
  }

  if (isLoading) return <LoadingImage />

  return (
    <article>
      <Question>
        <MDXRenderer>{question}</MDXRenderer>
      </Question>
      <Answer
        placeholder="ระบุคำตอบ..."
        onChange={changeAnswer}
        value={answer}
      />
      <SubmitAnswer onClick={submitAnswer}>ยืนยันคำตอบ</SubmitAnswer>
      <Timer>{remainingTime} วินาที</Timer>
    </article>
  )
}

export default TextQuestion
