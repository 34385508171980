import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg';
import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase';
import ToggableContent from '@babelcoder/gatsby-theme-base/src/components/ToggableContent';
import Tabs from '@babelcoder/gatsby-theme-base/src/components/Tabs';
import TabPane from '@babelcoder/gatsby-theme-base/src/components/TabPane';
import Codesandbox from '@babelcoder/gatsby-theme-base/src/components/Codesandbox';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import VideoLesson from './VideoLesson';
import DocumentLesson from './DocumentLesson';
import ExamLesson from './ExamLesson';
import LessonHeader from './LessonHeader';

const LESSON_TYPES = {
  video: VideoLesson,
  document: DocumentLesson,
  exam: ExamLesson,
};

const Content = styled.div`
  max-width: 992px;
  margin: 0 auto;
`;

const Desc = styled.p`
  color: ${({ theme }) => theme.colors.neutral.gray900};
`;

const Placeholder = styled.p`
  text-align: center;
`;

const EnrollLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.main.primary};
    text-decoration: underline;
  `}
`;

function LessonDetails({ course, lesson }) {
  const [secureLesson, setSecureLesson] = useState(null);
  const [registration, setRegistration] = useState();
  const [isRegistrationLoading, setIsRegistrationLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { getCoursePath } = useCoursesPath();
  const { user } = useGlobalState();
  const Component = LESSON_TYPES[lesson.type];

  const loadRegistration = useCallback(async () => {
    if (!user) return;

    try {
      const firestore = await getFirebaseFirestore();
      const doc = await firestore
        .doc(`users/${user.uid}/registrations/${course.slug}`)
        .get();

      setRegistration(doc.data());
    } catch (error) {
    } finally {
      setIsRegistrationLoading(false);
    }
  }, [user, course.slug]);

  const loadSecureLesson = useCallback(async () => {
    try {
      const firestore = await getFirebaseFirestore();
      const doc = await firestore
        .collection(`courses/${course.slug}/lessons`)
        .doc(lesson.slug)
        .get();

      setSecureLesson(doc.data());
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [lesson.slug, course.slug]);

  useEffect(() => {
    loadSecureLesson();
    loadRegistration();
  }, [loadSecureLesson, loadRegistration]);

  return (
    <ToggableContent>
      <Content>
        <LessonHeader lesson={lesson} course={course}></LessonHeader>
        {isRegistrationLoading ? (
          <LoadingImage></LoadingImage>
        ) : lesson.forLive &&
          !['live', 'enterprise'].includes(registration?.courseType) ? (
          <Placeholder>
            บทเรียนนี้เข้าถึงได้เฉพาะผู้ลงทะเบียนแบบคอร์สอบรมเท่านั้น
          </Placeholder>
        ) : (
          <>
            <Component
              course={course}
              {...lesson}
              isLoading={isLoading}
              secureLesson={secureLesson}
            ></Component>
            {lesson.type !== 'exam' && (
              <Tabs>
                <TabPane label="คำอธิบาย" index={0}>
                  <Desc>{lesson.desc}</Desc>
                </TabPane>
                {lesson.hasCode && (
                  <TabPane label="ซอร์จโค้ด" index={1}>
                    {secureLesson?.code ? (
                      <Codesandbox url={secureLesson.code}></Codesandbox>
                    ) : isLoading ? (
                      <LoadingImage></LoadingImage>
                    ) : secureLesson ? (
                      <Placeholder>ไม่พบซอร์จโค้ดสำหรับบทเรียนนี้</Placeholder>
                    ) : (
                      <Placeholder>
                        กรุณา{' '}
                        <EnrollLink to={getCoursePath(course.slug)}>
                          ลงทะเบียน
                        </EnrollLink>{' '}
                        ก่อนเข้าเรียนคอร์สดังกล่าว
                      </Placeholder>
                    )}
                  </TabPane>
                )}
                <TabPane label="ความคิดเห็น" index={2}></TabPane>
              </Tabs>
            )}
          </>
        )}
      </Content>
    </ToggableContent>
  );
}

export default LessonDetails;
