import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'

const Desc = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
    padding: ${theme.spacers.normal} 0;
    border-bottom: 1px solid ${theme.colors.neutral.gray400};
  `}
`

const Placeholder = styled.p`
  text-align: center;
`

const EnrollLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.main.primary};
    text-decoration: underline;
  `}
`

function DocumentLesson({ course, isLoading, secureLesson }) {
  const { getCoursePath } = useCoursesPath()

  return (
    <div>
      {secureLesson?.content ? (
        <MDXRenderer>{secureLesson.content}</MDXRenderer>
      ) : isLoading ? (
        <LoadingImage></LoadingImage>
      ) : (
        <Placeholder>
          กรุณา{' '}
          <EnrollLink to={getCoursePath(course.slug)}>ลงทะเบียน</EnrollLink>{' '}
          ก่อนเข้าเรียนคอร์สดังกล่าว
        </Placeholder>
      )}
    </div>
  )
}

export default DocumentLesson
